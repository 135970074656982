import React from "react";
import { Layout, Anchor, Typography, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import useIsMobile from "../hooks/useIsMobile";
import About from "./About";
import Terapy from "./Terapy";

const { Header, Content, Footer } = Layout;
const { Link } = Anchor;
const { Text } = Typography;

const items = [
  { key: "part-1", title: "Sobre", href: "#part-1" },
  { key: "part-2", title: "Terapia", href: "#part-2" },
];

const CustomLayout = () => {
  const [isMobile] = useIsMobile();

  return (
    <Layout style={{ width: "100%", overflowX: "hidden" }}>
      <Header
        style={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          background: "#7cdaf9",
          padding: isMobile ? "0 20px" : "0 20px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "30%",
            textAlign: isMobile ? "center" : "left",
            marginBottom: 0,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 24,
              fontFamily: "'Dancing Script', sans-serif",
              marginRight: 5,
            }}
          >
            Matheus Degli Esposti
          </Text>
          <span style={{ fontSize: 14 }}> - CRP 06/191634</span>
        </div>
        {!isMobile && (
          <div
            style={{
              padding: "20px",
              minWidth: 0,
              textAlign: "right",
              boxSizing: "border-box",
            }}
          >
            <Anchor direction="horizontal" targetOffset={80}>
              {items.map((item) => (
                <Link
                  key={item.key}
                  href={item.href}
                  title={<span style={{ color: "#000000" }}>{item.title}</span>}
                />
              ))}
            </Anchor>
          </div>
        )}
      </Header>
      <Content
        style={{
          marginTop: isMobile ? "60px" : "80px", // Adjust margin to account for fixed header
          padding: isMobile ? "0 10px" : "0 24px",
          width: "100%",
          maxWidth: "100%",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify="center"
          align="middle"
          style={{ width: "100%", margin: 0, overflow: "hidden" }}
        >
          <Col span={24} style={{ maxWidth: "100%" }}>
            <About />
            <Terapy />
          </Col>
        </Row>
      </Content>
      <Footer
        style={{
          textAlign: "center",
          padding: isMobile ? "10px 0" : "20px 0",
          boxSizing: "border-box",
        }}
      >
        ©{new Date().getFullYear()} Created by Élvio Gabriel Gravata
      </Footer>
      <div
        style={{
          position: "fixed",
          bottom: isMobile ? "10px" : "20px",
          right: isMobile ? "10px" : "20px",
          width: "50px",
          height: "50px",
          backgroundColor: "#25D366",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
          cursor: "pointer",
          zIndex: 1000,
          boxSizing: "border-box",
        }}
        onClick={() =>
          window.open(
            "https://wa.me/5519996679368?text=Olá,%20gostaria%20de%20saber%20mais%20sobre...",
            "_blank"
          )
        }
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          size="2x"
          style={{ color: "#FFFFFF" }}
        />
      </div>
    </Layout>
  );
};

export default CustomLayout;
