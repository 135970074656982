import React from "react";
import { theme, Card, Typography, Button, Row, Col, Alert } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import useIsMobile from "../hooks/useIsMobile";
import backgroundImage from "../images/backgroundImage.jpg";
import perfil from "../images/perfil.png";

const { Title, Text } = Typography;

const timelineItems = [
  {
    children: (
      <>
        <Title level={4}>Escuta Ativa e Empática</Title>
        <Text style={{ fontSize: 14 }}>
          Um espaço seguro onde suas preocupações são recebidas com atenção e
          sem julgamentos.
        </Text>
      </>
    ),
  },
  {
    children: (
      <>
        <Title level={4}>Estratégias Personalizadas</Title>
        <Text style={{ fontSize: 14 }}>
          Técnicas adaptadas às suas necessidades e ao seu ritmo, visando seu
          bem-estar e progresso.
        </Text>
      </>
    ),
  },
  {
    children: (
      <>
        <Title level={4}>Confidencialidade Absoluta</Title>
        <Text style={{ fontSize: 14 }}>
          Compromisso com a privacidade e a segurança das suas informações.
        </Text>
      </>
    ),
  },
];

function Info() {
  const [isMobile] = useIsMobile();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <Card
        id="part-1"
        style={{
          marginTop: isMobile && 10,
          marginBottom: 10,
          height: isMobile ? 360 : 200,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            opacity: 0.5,
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            zIndex: 2,
            transform: isMobile ? "translateY(-10%)" : "translateY(-25%)",
          }}
        >
          <Title style={{ textAlign: "center", marginTop: 30 }}>
            Atendimento online para jovens e adultos
          </Title>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 24,
              fontFamily: "'Dancing Script', sans-serif",
            }}
          >
            Matheus Degli Esposti
          </Text>
          <Text
            style={{ textAlign: "center", fontWeight: "bold", fontSize: 14 }}
          >
            CRP 06/191634
          </Text>
          <Button
            shape="round"
            size="large"
            icon={<WhatsAppOutlined />}
            style={{
              alignSelf: "center",
              width: isMobile ? "65%" : "25%",
              marginTop: 20,
              background: "#25D366",
              color: "#fff",
            }}
            onClick={() =>
              window.open(
                "https://wa.me/5519996679368?text=Olá,%20gostaria%20de%20saber%20mais%20sobre...",
                "_blank"
              )
            }
          >
            Vamos conversar!
          </Button>
        </div>
      </Card>
      <Card
        style={{
          background: colorBgContainer,
          height: "auto",
          padding: isMobile ? "16px" : "24px",
          borderRadius: borderRadiusLG,
          marginBottom: 20,
          position: "relative",
        }}
      >
        <Row
          gutter={[16, 16]}
          style={{
            position: "relative",
            paddingBottom: isMobile ? "24px" : "0",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={8}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: isMobile ? "20px" : "0",
            }}
          >
            <img
              src={perfil}
              alt="Imagem de Perfil"
              style={{
                width: isMobile ? "60%" : "70%", // Reduced width for smaller image
                height: "auto",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            style={{
              paddingTop: isMobile ? "0px" : "24px",
              textAlign: "justify",
            }}
          >
            <Text style={{ fontSize: 18 }}>
              Em momentos de desafios e mudanças, encontrar um espaço onde você
              possa ser ouvido(a) e compreendido(a) é essencial.
            </Text>
            <br />
            <Text style={{ fontSize: 18 }}>
              Sou Matheus, psicólogo, e estou aqui para oferecer um apoio
              genuíno e acolhedor, ajudando você a navegar pelas complexidades
              da vida com mais confiança e clareza.
            </Text>
            <br />
            <Text style={{ fontSize: 18 }}>
              Ofereço terapia para jovens e adultos, abordando questões como
              ansiedade, estresse, relacionamentos e desenvolvimento pessoal.
              Juntos, exploraremos caminhos para você se sentir mais
              equilibrado(a) e realizado(a) na sua vida cotidiana. Meu foco é
              criar um ambiente onde suas emoções e experiências são respeitadas
              e valorizadas. Acredito que, com o suporte certo, cada pessoa tem
              a capacidade de transformar seus desafios em oportunidades de
              crescimento e realização pessoal.
            </Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: "24px" }}>
          {timelineItems.map((item, index) => (
            <Col
              xs={24}
              sm={24}
              md={8}
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Alert
                message={item.children}
                style={{
                  width: "100%",
                  margin: 5,
                  textAlign: "center",
                  padding: isMobile ? "10px" : "20px",
                }}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
}

export default Info;
