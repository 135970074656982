import React from "react";
import { Typography, theme, Divider, Card, Row, Col, Button } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import useIsMobile from "../hooks/useIsMobile";
import ansiety from "../images/ansiety.png";
import depression from "../images/depression.png";
import relationship from "../images/relationship.png";
import addict from "../images/addict.png";
import selfKnowledge from "../images/selfKnowledge.png";
import disorders from "../images/disorders.png";

const { Text } = Typography;

const icons = [
  { description: "Ansiedade", icon: ansiety },
  { description: "Depressão", icon: depression },
  { description: "Relacionamentos", icon: relationship },
  { description: "Vícios", icon: addict },
  { description: "Auto conhecimento", icon: selfKnowledge },
  { description: "Transtornos", icon: disorders },
];

function About() {
  const [isMobile] = useIsMobile();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Card
      id="part-2"
      style={{
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
        padding: isMobile ? "16px" : "24px",
        marginBottom: 20,
        position: "relative",
      }}
    >
      <Divider
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ? 18 : 20,
          marginTop: -15,
        }}
      >
        Em que posso te ajudar:
      </Divider>
      <Row gutter={[16, 16]} justify="center" style={{ marginTop: 20 }}>
        {icons.map((item, index) => (
          <Col
            xs={12}
            sm={8}
            md={8}
            lg={4}
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: isMobile ? "30px" : "0",
            }}
          >
            <Text style={{ fontSize: isMobile ? 14 : 16 }}>
              {item.description}
            </Text>
            <img
              src={item.icon}
              alt={item.description}
              width="48"
              height="48"
              style={{ marginBottom: "10px" }}
            />
          </Col>
        ))}
      </Row>
      <Divider
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ? 18 : 20,
          marginTop: isMobile ? "40px" : "60px",
          marginLeft: isMobile && -20,
        }}
      >
        Como a Terapia pode me ajudar?
      </Divider>
      <Text
        style={{
          fontSize: 18,
          display: "block",
          marginTop: "20px",
          textAlign: "justify",
        }}
      >
        A terapia proporciona um espaço seguro para entender melhor seus
        pensamentos e emoções. Ela ajuda a gerenciar estresse e ansiedade,
        melhorar relacionamentos e superar traumas passados. Além disso, apoia
        na tomada de decisões ao esclarecer valores e prioridades, e ensina
        habilidades práticas para enfrentar desafios diários. Em suma, a terapia
        é uma ferramenta valiosa para promover o crescimento pessoal e o
        bem-estar geral.
      </Text>
      <Divider
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ? 18 : 20,
          marginTop: isMobile ? "40px" : "60px",
          marginLeft: isMobile && -20,
        }}
      >
        A Terapia Online Funciona?
      </Divider>
      <Text
        style={{
          fontSize: 18,
          display: "block",
          marginTop: "20px",
          textAlign: "justify",
        }}
      >
        Sim, a terapia online funciona e pode ser uma verdadeira mudança de
        vida. Em um mundo onde o tempo é precioso e as responsabilidades são
        muitas, a terapia online oferece a liberdade de buscar ajuda de qualquer
        lugar, sem sair de casa. Isso é especialmente valioso para quem enfrenta
        dificuldades para se deslocar ou tem uma agenda apertada. Estudos
        mostram que a terapia online é tão eficaz quanto a presencial para lidar
        com desafios emocionais. Através do atendimento online, é possível
        construir uma conexão genuína, onde existe apoio e compreensão.
        Portanto, a terapia online não é apenas uma opção prática; é uma
        oportunidade de se cuidar e encontrar equilíbrio de maneira acessível e
        eficaz, no seu próprio ritmo e espaço. É um passo valioso em direção ao
        bem-estar, onde quer que você esteja.
      </Text>
      <div
        style={{
          textAlign: "center",
          marginTop: isMobile ? "40px" : "60px",
        }}
      >
        <Button
          shape="round"
          size="large"
          icon={<WhatsAppOutlined />}
          style={{
            width: isMobile ? "80%" : "20%",
            background: "#25D366",
            color: "#fff",
          }}
          onClick={() =>
            window.open(
              "https://wa.me/5519996679368?text=Olá,%20gostaria%20de%20saber%20mais%20sobre...",
              "_blank"
            )
          }
        >
          Agendar agora!
        </Button>
      </div>
    </Card>
  );
}

export default About;
